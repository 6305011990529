@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--colorGrey100); /* Loading BG color */
  border-radius: var(--borderRadiusMedium);

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.rootForImage {
  border: solid 1px var(--colorGrey100);
  background-color: var(--colorWhite);
  border-radius: var(--borderRadiusMedium);
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 14px 0 2px 0;
}
.label {
  display: none;
  margin: 0;
  color: var(--colorGrey700);
  font-weight: 400;

  @media (--viewportSmall) {
    display: block;
  }
}
.labelMobile {
  margin: 0;
  color: var(--colorGrey700);
  font-weight: 400;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-all;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;

  /* @media (--viewportSmall) {
    display: none;
    width: 200px;
  } */
}
.price {
  /* Layout */
  composes: textSmall from global;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
  margin-top: 0.75rem;
  @media (--viewportMedium) {
    margin-top: 0.35rem;
  }
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: 700;
  color: var(--colorGrey700);
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightBold);
  color: var(--colorGrey700);
  margin: 0;
  text-transform: uppercase;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
.sizeContainer {
  margin: 0;
  color: var(--colorGrey700);
  font-size: 14px;
  font-weight: 500;
  padding-top: 0;
}
.sizeContainerIfNo {
  margin: 0;
  color: var(--colorGrey700);
  font-size: 14px;
  font-weight: 600;
  opacity: 0;
  padding-top: 0;
}
