@import '../../styles/customMediaQueries.css';
.root {
  /* padding: 0.5rem; */
  width: 100%;
  margin-bottom: 1rem;
  /* max-height: 350px; */
  overflow: scroll;
}
.trigger {
  display: flex;
  justify-content: space-between;
  padding: 0.6rem 0.25rem;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 0.5rem;
  color: var(--colorGrey800);
  font-weight: 400;
}
.triggerSub {
  display: flex;
  justify-content: space-between;
  padding: 0.6rem 1.5rem;
  padding-right: 0.25rem;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 0.5rem;
  color: var(--colorGrey800);
  font-weight: 400;
}
.triggerText {
  color: var(--colorGrey800);
}
.triggerTextActive {
  color: var(--colorGrey800);
  border-bottom: 1px solid var(--colorGrey800);
}
.collapsibleBody {
  margin: 1rem 2.5rem;
  margin-top: 0px;
}
.label {
  color: var(--colorGrey800);
  text-decoration: none;
  cursor: pointer;
  line-height: 1.4;
  font-size: 16px;
  font-weight: 400;
  &:hover {
    color: var(--marketplaceColorDark);
    text-decoration: none;
  }
}
.ul {
  display: grid;
  margin: 0;
  gap: 0.5rem;
}
.li {
  margin: 0;
  padding: 0;
  padding-bottom: 0.35rem;
}
.columns {
  /* display: flex; */
  /* justify-content: space-evenly; */
}
.columnTitle {
  margin: 0;
  font-size: 20px;
  padding: 0;
}
.container {
}
