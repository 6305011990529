.root {
}

.inputError {
  border: var(--borderErrorField);
}
.subHeading {
  margin: 0;
  color: var(--colorGrey300);
  /* font-size: 13px; */
}
.subheadingSignup {
  margin: 0;
  color: var(--colorGrey300);
  font-size: 13px;
}
