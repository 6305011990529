@import '../../styles/customMediaQueries.css';
.menuContainer {
  display: none;
  @media (--viewportMedium) {
    display: flex;
    gap: 1.5rem;
    padding: 0rem;
    /* padding: 0.5rem; */
    padding-left: 36px;
    width: 100%;
    background-color: var(--colorWhite);
    border-bottom: 1px solid var(--colorGrey100);
    /* max-width: var(--contentMaxWidthPages); */
    /* border-top: 1px solid var(--colorGrey100); */
  }
}
.main {
  display: none;
  @media (--viewportMedium) {
    display: flex;
    gap: 1.5rem;
    padding: 0rem;
    /* padding: 0.5rem; */
    /* padding-left: 36px; */
    width: 100%;
    margin: auto;
    background-color: var(--colorWhite);
    max-width: 1800px;
    /* max-width: var(--contentMaxWidthPages); */
    /* border-top: 1px solid var(--colorGrey100); */
  }
}
.notShow {
  display: none;
}

.menuItem {
  position: relative;
  padding: 0.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;

  /* padding-top: 0.5rem;
  padding-bottom: 0.5rem; */
  color: var(--colorBlack);
  border-bottom: 2px solid var(--colorWhite);

  &:hover {
    /* background-color: #ccc; */
    border-bottom: 2px solid var(--marketplaceColor);
  }
}

.submenu {
  display: none;
  position: absolute;
  width: max-content;
  left: calc(50% - 150px);
  top: 105%;
  background: #fff;
  border: 1px solid #ccc;
  border-top: unset;
  padding: 10px;
  z-index: 99;
  min-height: 440px;

  @media (min-width: 1300px) {
    left: 0;
  }
}
.main > :last-child {
  .submenu {
    right: 0;
    left: unset;
    @media (min-width: 1400px) {
      right: unset;
      left: -20px;
    }
  }
}

.subMenuSingle {
  display: none;
  position: absolute;
  width: max-content;
  top: 105%;
  left: 0;
  background: #fff;
  border: 1px solid #ccc;
  border-top: unset;
  padding: 10px;
  z-index: 99;
  min-height: 440px;
}

.menuItem:hover .submenu {
  display: flex !important;
  padding: 1.5rem;
  padding-bottom: 0.25rem;
  gap: 1rem;
  min-height: 440px;
}
.menuItem:hover .subMenuSingle {
  display: flex;
  padding: 1.5rem;

  padding-bottom: 0.25rem;
  min-width: 350px;
  min-height: 440px;
}
.submenu.show {
  display: flex;
  padding: 1.5rem;
  padding-bottom: 0.25rem;
  gap: 1rem;
}

.subMenuSingle.show {
  display: flex;
  padding: 1.5rem;
  padding-bottom: 0.25rem;
}

.label {
  color: var(--colorBlack);
  text-decoration: none;
  line-height: 1.3;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}
.optionLabel {
  color: var(--colorBlack);
  text-decoration: none;
  line-height: 1.8;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.subMenuLeft {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.subMenuRight {
  margin-left: 2rem;
}
.image {
  width: 100%;
  height: 100%;
}
.ul {
  /* margin: 0; */
}
/* .ulGreater {
  column-count: 2;
  column-gap: 40px;
} */
.ulGreater {
  column-count: 2;
  column-gap: 40px;
}

.ulGreater li:nth-child(11) {
  break-before: column; /* Ensures the 11th element starts in the next column */
}
.li {
  margin: 0;
  padding: 0;

  padding-bottom: 0.25rem;
}
.subMenuTitle {
  margin-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #dcc8e1;
  /* color: darkgray; */
  font-size: 16px;
}
/* .columns {
  display: flex;
  justify-content: space-between;
  gap: 3rem;
} */
.columns {
  display: flex;
  justify-content: space-between;
  gap: 4.5rem;
}

.column {
  position: relative;
}

.column:not(:first-child)::before {
  content: '';
  position: absolute;
  top: 0;
  left: -2rem;
  width: 1px;
  height: 100%;
  background-color: var(--colorGrey100);
}

.columnTitle {
  margin: 0;
}
