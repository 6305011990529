@import '../../styles/customMediaQueries.css';
.select {
  margin-top: 1rem;
  width: auto;
  @media (--viewportMedium) {
    /* margin-top: 1rem; */
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
.normalSelect {
  width: 84px;
  margin-top: 0;
  font-size: 15px;
  padding-left: 0;
  border: none;
  box-shadow: none;
  cursor: pointer;
  color: var(--matterColor);

  @media (--viewportMedium) {
    /* margin-top: 1rem; */
    margin-left: 0;
    margin-right: 1rem;
  }
  &:hover {
    color: var(--matterColor);
    box-shadow: none;
    border-bottom: 4px solid var(--marketplaceColor);
  }
  &:focus {
    color: var(--matterColor);
    border: none;
    outline: none;
    box-shadow: none;
  }
}
.normalSelect:focus {
  color: var(--matterColor);
  border: none;
  outline: none;
  box-shadow: none;
}
.normalSelect:hover {
  color: var(--matterColor);
  box-shadow: none;
  border: none;
  border-radius: none;
  border-bottom: none;
}

.worldIcon {
  color: var(--marketplaceColor);
  fill: none;
  font-size: 18px;
  margin-top: 0;
  margin-right: 0.5rem;
  @media (--viewportMedium) {
    /* margin-top: 0.85rem; */
  }
}

.normalSelectLandingPage {
  width: 100px;
  /* margin-top: 10px; */
  font-size: 14px;
  color: var(--matterColor);
  @media (--viewportMedium) {
    color: white;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
.label {
  font-size: 14px;
}
.changeFormContainer {
  height: 100%;
}

.parentt {
  display: flex;
  align-items: center;
}
.selectedLabel {
  composes: textSmall from global;
  margin: 1.8rem 0.5rem 1.8rem 0rem;
  /* margin:1.5rem 0.5rem 1.5rem 0rem; */
}
.option {
  display: flex;
  /* padding: 0.2rem 0.2rem 0.1rem 0.2rem;
  margin-left: 10px; */
  padding: 0.5rem;
  border-bottom: 1px solid var(--colorGrey100);
}

.optionSecond {
  display: flex;
  /* padding: 0.1rem 0.2rem 0.2rem 0.2rem;
  margin-left: 10px; */
  padding: 0.5rem;
}
.label {
  font-size: 15px;
  /* margin-bottom: 0.25rem; */
}
.checkMark {
  font-size: 25px;
  /* margin-left: 0.5rem; */
}
.check {
  border: 1px solid red;
}
.profileMenuContent {
  /* padding: 0.5rem 0.5rem; */
  width: 130px;
}
.changeLanguageForm {
  display: flex;
  align-items: center;
  margin-left: 0px;
  @media (--viewportLarge) {
    margin-left: 10px;
    height: 69px;
  }
}
