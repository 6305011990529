@import '../../styles/customMediaQueries.css';
.root {
  display: flex;
  flex-direction: column;
  align-items: unset;
  min-height: 100vh;
  @media (--viewportXLarge) {
    /* align-items: center; */
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.scrollingDisabled {
  /* position: fixed and width are added to prevent iOS from scrolling content */
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
